import { graphql, useStaticQuery } from "gatsby";

export const BarQuery = () => {
  let query = useStaticQuery(
    graphql`
      query {
        allContentfulBarSida {
          edges {
            node {
              Synligataggar
              seoTitel
              seoDescription
              node_locale
              heroUnderRubrik {
                heroUnderRubrik
              }
              heroRubrik
              introText {
                childContentfulRichText {
                  html
                }
              }
              heroBild {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
                file {
                  contentType
                  url
                }
              }
            }
          }
        }
      }
    `
  );

  return query.allContentfulBarSida
};
